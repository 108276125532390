import { Box, Button, Heading, HStack, Icon, Label, Link, Text, VStack } from '@medely/ui-kit';
import { FixedFooterLayout } from '../../FixedFooterLayout/index';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';
import ProPool from '../../../assets/ProPool';
import React from 'react';
import useProPoolInviteMutations from './mutations';

export const Invite = () => {
  const [{ facilityName, professionalQualificationId }] = useQueryParams({
    facilityName: StringParam,
    professionalQualificationId: NumberParam,
  });

  const { acceptPoolInvite, rejectPoolInvite, isSubmittingAccept, isSubmittingReject } =
    useProPoolInviteMutations(facilityName || 'the facility');

  const handleAccept = () => {
    if (professionalQualificationId) {
      acceptPoolInvite(professionalQualificationId);
    }
  };

  const handleReject = () => {
    if (professionalQualificationId) {
      rejectPoolInvite(professionalQualificationId);
    }
  };

  const areButtonsDisabled =
    isSubmittingAccept || isSubmittingReject || !professionalQualificationId;

  return (
    <FixedFooterLayout
      footerContent={
        <HStack justifyContent="center" gap={1}>
          <Button
            variant="outlined"
            onClick={handleReject}
            loading={isSubmittingReject}
            disabled={areButtonsDisabled}
          >
            No thanks
          </Button>
          <Button onClick={handleAccept} loading={isSubmittingAccept} disabled={areButtonsDisabled}>
            I&apos;m interested
          </Button>
        </HStack>
      }
    >
      <VStack alignItems="center">
        <VStack alignItems="center" maxWidth="343px">
          <Box mb={2}>
            <ProPool />
          </Box>
          <VStack alignItems="center" mb={4}>
            <Heading size="s">Congratulations!</Heading>
            <Text size="l">You&apos;re invited to join the Professional Pool at</Text>
            <HStack>
              <Text bold size="l">
                {facilityName}
              </Text>
              <Text size="l">!</Text>
            </HStack>
          </VStack>
          <VStack mb={2} px={1.5} py={2} gap={1.5} bgColor="surface.secondary" borderRadius="8px">
            <Label size="m">Benefits to joining a facilities pool</Label>
            <HStack gap={2} alignItems="center">
              <Box height={24} width={24}>
                <Icon
                  variant="custom"
                  color="state.success.secondary"
                  name="circle-check"
                  boxSize="24px"
                />
              </Box>
              <Text size="m">
                Earn more with priority access to shifts paid at per diem market rates or higher.
              </Text>
            </HStack>
            <HStack gap={2} alignItems="center">
              <Box height={24} width={24}>
                <Icon
                  variant="custom"
                  color="state.success.secondary"
                  name="circle-check"
                  boxSize="24px"
                />
              </Box>
              <Text size="m">Work consistently with the same facility.</Text>
            </HStack>
            <HStack gap={2} alignItems="center">
              <Box height={24} width={24}>
                <Icon
                  variant="custom"
                  color="state.success.secondary"
                  name="circle-check"
                  boxSize="24px"
                />
              </Box>
              <Text size="m">
                Remain flexible and continue to book shifts at any Medely location.
              </Text>
            </HStack>
          </VStack>
          <Text size="m">
            *Note: Expressing interest does not guarantee pool placement. Placement depends on
            meeting further facility requirements.
          </Text>
          <HStack position="absolute" bottom="92px">
            <Box pr={0.5}>
              <Text size="m">Have questions?</Text>
            </Box>
            <Link
              variant="text"
              size="m"
              url="https://help.medely.com/en/articles/10270319-what-are-professional-pools"
            >
              Learn more
            </Link>
            <Text size="m">.</Text>
          </HStack>
        </VStack>
      </VStack>
    </FixedFooterLayout>
  );
};
