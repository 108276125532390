import { NavigateProvider, useNavigateContext } from '@medely/ui-kit';
import React, { type PropsWithChildren } from 'react';
import { proRoutes, type ProRoutes } from '../routes';

export function ProNavigateProvider(props: PropsWithChildren) {
  return <NavigateProvider routes={proRoutes}>{props.children}</NavigateProvider>;
}

export function useProNavigate() {
  return useNavigateContext<ProRoutes>();
}
