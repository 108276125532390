import { type Routes } from '@medely/ui-kit';

// Key = Route name
// Value = Params type
// Optional params should use | undefined, not ?
// Currently only primitive values are supported.
export type ProRoutes = {
  AdjustmentDetail: {
    id: number;
  };
  Application: never;
  AvailableAssignmentsLocal: never;
  EarningsPayoutDetails: {
    id: number;
  };
  EarningsPending: never;
  JobDetails: {
    id: number;
  };
  Profile: never;
  ProPoolInvite: {
    locationId: number;
    facilityName: string;
    professionalQualificationId: number;
  };
  ProPoolInviteAccepted: {
    facilityName: string;
  };
  LocationOnboarding: {
    location_id: number;
  };
  Today: never;
};

export const proRoutes: Routes<ProRoutes> = {
  AdjustmentDetail: {
    url: '/earnings/adjustments/:id',
  },
  Application: {
    url: '/application',
  },
  AvailableAssignmentsLocal: {
    url: '/available/assignments/local',
  },
  EarningsPayoutDetails: {
    url: '/earnings/payouts/:id',
  },
  EarningsPending: {
    url: '/earnings/pending',
  },
  JobDetails: {
    url: '/jobs/:id',
  },
  Profile: {
    url: '/profile',
  },
  ProPoolInvite: {
    url: '/pro-pool/:locationId/invite',
  },
  ProPoolInviteAccepted: {
    url: '/pro-pool/invite-accepted',
  },
  LocationOnboarding: {
    url: 'onboarding/location/:location_id',
  },
  Today: {
    url: '/',
  },
};
