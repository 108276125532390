import { Typography } from '@medely/web-components';
import { TextField } from '@medely/web-components/forms';
import { Field } from 'react-final-form';
import React from 'react';
import { required } from 'core/utils/validation';
import { IQualificationAttribute } from '@medely/types';
import { useExperiment } from 'statsig-react';
import { experimentNames } from '@medely/pro-multi-platform';

const PALS_ECODE_SLUG = 'pals_ecode';

type LicenseNumberFieldProps = {
  name: string;
  isRequired: boolean;
  withNameLabel?: boolean;
  label: string;
  qualificationAttribute: IQualificationAttribute;
};

/**
 * This field is only separated out for the purpose of running an experiment on adding the optional ecode field for AHA qualifications.
 * Entering a value in this field will allow for automation of AHA qualification approvals, but there is a concern regarding dropoff
 * of qualification uploads when adding the extra fields.
 *
 * Revisit this as part of INT-376
 */
const EcodeField = ({
  name,
  isRequired,
  withNameLabel,
  label,
}: Omit<LicenseNumberFieldProps, 'qualificationAttribute'>) => {
  const { config } = useExperiment(experimentNames.ahaEcode.name);
  const showField = config.get('show_ecode_field', false);
  return showField ? (
    <>
      {withNameLabel && <Typography gutterBottom>{label}</Typography>}
      <Field
        name={`${name}.value`}
        validate={isRequired ? required : undefined}
        data-testid="license-number"
      >
        {({ input }) => <TextField label={label} {...input} />}
      </Field>
    </>
  ) : null;
};

const LicenseNumber = ({
  name,
  isRequired,
  withNameLabel,
  label,
  qualificationAttribute,
}: LicenseNumberFieldProps) => {
  if (qualificationAttribute?.slug === PALS_ECODE_SLUG) {
    return (
      <EcodeField name={name} isRequired={isRequired} withNameLabel={withNameLabel} label={label} />
    );
  }
  return (
    <>
      {withNameLabel && <Typography gutterBottom>{label}</Typography>}
      <Field
        name={`${name}.value`}
        validate={isRequired ? required : undefined}
        data-testid="license-number"
      >
        {({ input }) => <TextField label={label} {...input} />}
      </Field>
    </>
  );
};

export default LicenseNumber;
