import React from 'react';
import { isEmpty } from 'lodash';

import {
  Box,
  CircularProgress,
  DesignTokenColor,
  Heading,
  HStack,
  Icon,
  Label,
  Text,
  VStack,
} from '@medely/ui-kit';
import { EarningsJobListItem } from './EarningsJobListItem';
import { AdjustmentListItem } from './AdjustmentListItem';
import { useGate } from '../../hooks/useGate';
import usePendingEarnings from '../../hooks/usePendingEarnings';
import { centsToCurrency, pendingHours, pluralize, splitAmountsByStatus } from '../../utils';
import { formatPendingJobForListItem } from './utils';
import {
  EarningsPendingAdjustment,
  EarningsPendingJobBilling,
  PendingEarningsProps,
} from './interface';
import { useProNavigate } from '../../contexts';
import { HelpCenterLink } from '../helpCenterLink';
import { gateNames } from '../../constants/statsig';

export const EarningsPendingSummary = () => {
  const onError = (e: Error) => {
    console.error(e);
  };
  const { adjustments, jobs, isLoading, hasPendingEarnings } = usePendingEarnings(onError);

  if (isLoading) {
    return <CircularProgress />;
  } else if (!hasPendingEarnings) {
    return null;
  }

  return (
    <VStack gap={2}>
      <HStack justifyContent="space-between" alignItems="center">
        <Heading size="m">Pending</Heading>
        <HelpCenterLink configKey="learnAboutPayouts" text="Learn about payouts" />
      </HStack>
      <PendingEarnings adjustments={adjustments} jobs={jobs} />
    </VStack>
  );
};

const PendingEarnings = ({ adjustments, jobs }: PendingEarningsProps) => {
  const { value: isSplitPayEnabled } = useGate(gateNames.splitPay);
  const { navigate } = useProNavigate();

  if (adjustments.length === 1 && isEmpty(jobs)) {
    return (
      <AdjustmentListItem
        adjustment={adjustments[0]}
        onClick={() => navigate('AdjustmentDetail', { id: adjustments[0].id })}
      />
    );
  } else if (jobs.length === 1 && isEmpty(adjustments)) {
    const formattedJob = formatPendingJobForListItem(jobs[0]);
    return (
      <EarningsJobListItem
        variant="pending"
        job={formattedJob}
        onClick={() => navigate('JobDetails', { id: formattedJob.id })}
      />
    );
  }

  const formattedAdjustments = adjustments.map((adjustment) => ({
    amount_cents: adjustment.amount_cents,
    payout: adjustment.payout?.status ? { status: adjustment.payout?.status } : null,
  }));

  const collectedJobBillings: (EarningsPendingJobBilling & { job: { isJobSplit: boolean } })[] = [];
  const jobRelatedAdjustments: EarningsPendingAdjustment[] = [];

  jobs.forEach((job) => {
    if (isEmpty(job.job_billings) && isEmpty(job.expense_adjustments)) {
      return;
    }

    const isJobSplit = job.job_billings.some((jb) => jb.category === 'disputable');
    job.job_billings.forEach((jb) => {
      collectedJobBillings.push({
        ...jb,
        job: {
          status: job.status,
          isJobSplit,
        },
        payout: jb.payout?.status
          ? { status: jb.payout.status, amount_cents: jb.payout.amount_cents }
          : null,
        disputed_job_billings: jb.disputed_job_billings,
      });
    });

    job.expense_adjustments.forEach((adj) => {
      jobRelatedAdjustments.push(adj);
    });
  });

  const { disputed, inReview, waitingForPayout } = splitAmountsByStatus(
    collectedJobBillings,
    [...formattedAdjustments, ...jobRelatedAdjustments],
    isSplitPayEnabled,
  );
  const totalHours = pendingHours(collectedJobBillings);

  const description = [
    ...(jobs.length ? [pluralize(jobs.length, 'shift')] : []),
    ...(totalHours ? [pluralize(totalHours, 'hr')] : []),
    ...(adjustments.length ? [pluralize(adjustments.length, 'adjustment')] : []),
  ].join(' · ');

  return (
    <HStack
      alignItems="center"
      justifyContent="space-between"
      onClick={() => navigate('EarningsPending')}
    >
      <VStack flex={1} gap={0.5}>
        <Text size="m" color="text.secondary">
          {description}
        </Text>
        <Box borderTopWidth={1} borderColor="controls.backing+line" />
        {!!waitingForPayout ? (
          <LeftRight
            left="Ready to be paid"
            right={centsToCurrency(waitingForPayout)}
            color="text.secondary"
          />
        ) : null}
        {!!inReview ? (
          <LeftRight
            left="In review"
            right={centsToCurrency(inReview)}
            color="state.warning.secondary"
          />
        ) : null}
        {!!disputed ? (
          <LeftRight
            left="Disputed"
            right={centsToCurrency(disputed)}
            color="state.error.secondary"
          />
        ) : null}
        <Box borderTopWidth={1} borderColor="controls.backing+line" />
        <HStack justifyContent="space-between">
          <Label size="m">Total</Label>
          <Text size="m" color="text.secondary">
            {centsToCurrency(waitingForPayout + inReview + disputed)}
          </Text>
        </HStack>
      </VStack>
      <Box pl={1}>
        <Icon name="chevron-right" color="secondary" />
      </Box>
    </HStack>
  );
};

const LeftRight = ({
  left,
  right,
  color,
}: {
  left: string;
  right: string;
  color: DesignTokenColor;
}) => (
  <HStack justifyContent="space-between">
    <Text size="m" color={color}>
      {left}
    </Text>
    <Text size="m" color={color}>
      {right}
    </Text>
  </HStack>
);
