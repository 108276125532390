import {
  ConfigurationProvider,
  FirebaseProvider,
  AnalyticsProvider,
  ToastProvider,
} from '@medely/ui-kit';
import App from 'App';
import config from 'config';
import ErrorBoundary from 'core/containers/ErrorBoundary';
import moment from 'moment-timezone';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { auth } from 'utils/firebase';

window.moment = moment;

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <ErrorBoundary>
      <ToastProvider>
        <SnackbarProvider
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          hideIconVariant
          maxSnack={3}
        >
          <QueryParamProvider ReactRouterRoute={Route}>
            <AnalyticsProvider writeKey={config.segmentWriteKey}>
              {/* @ts-ignore */}
              <ConfigurationProvider configuration={config}>
                <FirebaseProvider auth={auth}>
                  <App />
                </FirebaseProvider>
              </ConfigurationProvider>
            </AnalyticsProvider>
          </QueryParamProvider>
        </SnackbarProvider>
      </ToastProvider>
    </ErrorBoundary>
  </BrowserRouter>,
);
