import { Box, CircularProgress, RichText, Tabs, Tab } from '@medely/web-components';
import { Typography } from '@medely/ui-kit';
import useQualification from 'hooks/useQualification';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useGate } from 'statsig-react';
import {
  AutomationQualificationT,
  sortQualificationAttributes,
  buildQualificationAttributes,
} from 'utils/professionalQualifications';
import Boolean from './attributeFields/Boolean';
import Date from './attributeFields/Date';
import Enumerable from './attributeFields/Enumerable';
import EnumerableMulti from './attributeFields/EnumerableMulti';
import ExpirationDate from './attributeFields/ExpirationDate';
import Image from './attributeFields/Image';
import Integer from './attributeFields/Integer';
import LicenseNumber from './attributeFields/LicenseNumber';
import PDF from './attributeFields/PDF';
import SignedDocument from './attributeFields/SignedDocument';
import State from './attributeFields/State';
import String from './attributeFields/String';
import Text from './attributeFields/Text';
import ValidDate from './attributeFields/ValidDate';
import isEmpty from 'lodash/isEmpty';
import Checkbox from './attributeFields/Checkbox';
import Rating from './attributeFields/skillsAssessment/Rating';
import { gateNames } from '@medely/pro-multi-platform';

const COMPONENT_MAPPING = {
  boolean: Boolean,
  checkbox: Checkbox,
  date: Date,
  enumerable: Enumerable,
  enumerable_multi: EnumerableMulti,
  expiration_date: ExpirationDate,
  image_back: Image,
  image_front: Image,
  integer: Integer,
  license_number: LicenseNumber,
  pdf: PDF,
  signed_document: SignedDocument,
  state: State,
  string: String,
  text: Text,
  valid_date: ValidDate,
  rating: Rating,
};

export type QualificationFormFieldsArgs = {
  name: string;
  preserveInitialValues?: boolean;
  qualification?: AutomationQualificationT;
  qualificationId?: number;
  withNameLabels?: boolean;
  withQualificationName?: boolean;
  updateForm?: boolean;
};

const MANUAL_USER_SOURCES = ['user', 'professional'];
const AUTOMATION_USER_SOURCES = [...MANUAL_USER_SOURCES, 'user_automation'];

const QualificationFormFields = ({
  name,
  qualification: inputQualification,
  qualificationId,
  preserveInitialValues,
  withNameLabels,
  withQualificationName,
  updateForm = true,
}: QualificationFormFieldsArgs) => {
  const form = useForm();
  const isSubmitting = form.getState().submitting;
  // Using state instead of form value to avoid tab changing on submit
  const [selectedAutomationId, setSelectedAutomationId] = useState(0);

  useEffect(() => {
    form.change('qualification_automation_id', selectedAutomationId);
  }, [selectedAutomationId]);

  const handleAutomationId = (_, value) => setSelectedAutomationId(value);
  const { qualification: foundQualification } = useQualification(qualificationId);

  const qualification = inputQualification ?? foundQualification;

  const { value: automationGateOpen } = useGate(gateNames.qualificationAutomationEnabled);
  const showAutomations = !isEmpty(qualification?.qualification_automations) && automationGateOpen;
  const selectedAutomation =
    showAutomations && !!selectedAutomationId
      ? qualification?.qualification_automations.find(({ id }) => id === selectedAutomationId)
      : undefined;

  useEffect(() => {
    // Switches to manual on error
    !isSubmitting && setSelectedAutomationId(0);
  }, [isSubmitting]);

  useEffect(() => {
    const shouldFormatQualification = qualificationId && !inputQualification;
    setSelectedAutomationId(0);

    // default to the automation if it's present and will be shown as an option
    if (showAutomations) {
      setSelectedAutomationId(qualification.qualification_automations?.[0]?.id);
    }

    if (qualification && (shouldFormatQualification || (!preserveInitialValues && updateForm))) {
      const formattedQualification = shouldFormatQualification
        ? buildQualificationAttributes(qualification)
        : qualification;

      form.change('qualification', {
        ...formattedQualification,
        qualification_attributes: ['application_q_and_a', 'q_and_a'].includes(
          formattedQualification.qualification_type,
        )
          ? formattedQualification.qualification_attributes
          : formattedQualification.qualification_attributes.sort(sortQualificationAttributes),
      });
    }
  }, [qualification?.id]);

  return qualification ? (
    <Box position="relative">
      {isSubmitting && (
        <Box sx={{ position: 'absolute' }} height="100%" width="100%">
          {/* @ts-ignore */}
          <Box
            sx={{ position: 'absolute', zIndex: 10, opacity: 0.9 }}
            height="100%"
            width="100%"
            backgroundColor="white"
          />
          <Box
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={2}
            sx={{ position: 'absolute', zIndex: 11 }}
            display="flex"
            height="100%"
            width="100%"
          >
            <CircularProgress />
            {selectedAutomation && (
              <Typography color="text.secondary" variant="label" size="m">
                Verifying your {qualification.long_name} with {selectedAutomation.name}
              </Typography>
            )}
          </Box>
        </Box>
      )}
      {withQualificationName && (
        <Typography data-testid="qualification-form-fields-title">
          {qualification.long_name}
        </Typography>
      )}
      {showAutomations ? (
        <Box pt={2}>
          <Typography color="text.secondary" variant="label" size="s">
            How would you like to upload your {qualification.long_name} credential?
          </Typography>
          <Box pt={1} pb={selectedAutomation?.description ? 3 : 0}>
            <Tabs value={selectedAutomationId} onChange={handleAutomationId}>
              {qualification.qualification_automations?.map(({ id, name }) => (
                <Tab
                  data-testid={`automation-tab-${id}`}
                  disabled={isSubmitting}
                  key={`${qualification?.id}-qualification_automation-${id}`}
                  value={id}
                  label={name}
                />
              ))}
              <Tab
                value={0}
                label={qualification.default_verification_title ?? 'Manual'}
                data-testid="manual-tab"
              />
            </Tabs>
          </Box>
          {selectedAutomation?.description && (
            <Typography color="text.primary" variant="label" size="s">
              {selectedAutomation.description}
            </Typography>
          )}
        </Box>
      ) : null}
      {!isEmpty(qualification?.instructions) && (
        <Box mt={2} data-testid="qualification-form-instructions">
          <RichText value={qualification.instructions} />
        </Box>
      )}
      <FieldArray name={`${name}.qualification_attributes`}>
        {({ fields }) => {
          let sortedAttributes;

          if (fields.value) {
            sortedAttributes =
              qualification.qualification_type === 'q_and_a'
                ? fields.value
                : fields.value.sort(sortQualificationAttributes);
          }

          return fields.map((name, index) => {
            const qualificationAttribute = sortedAttributes
              ? sortedAttributes[index]
              : fields.value[index];
            const Component = COMPONENT_MAPPING[qualificationAttribute.field_type];

            const hasAutomationSelected = !!selectedAutomationId;
            const isAutomationField = !!qualificationAttribute?.automationId;
            const isQualificationInSelectedAutomation = hasAutomationSelected
              ? qualificationAttribute.automationId === selectedAutomationId
              : !isAutomationField;
            const validUserSources = isAutomationField
              ? AUTOMATION_USER_SOURCES
              : MANUAL_USER_SOURCES;
            const isFieldInValidSource = validUserSources.includes(
              qualificationAttribute.input_source,
            );
            const isFieldActive = qualificationAttribute.active;

            const isFieldDisplayed =
              isFieldActive && isFieldInValidSource && isQualificationInSelectedAutomation;

            return isFieldDisplayed && isFieldActive ? (
              <Box key={qualificationAttribute.id} my={2} data-testid="qualification-form-fields">
                <Component
                  isRequired={qualificationAttribute.required}
                  name={name}
                  qualification_attribute_id={qualificationAttribute.id}
                  qualificationAttribute={qualificationAttribute}
                  label={qualificationAttribute.name}
                  withNameLabel={withNameLabels}
                />
              </Box>
            ) : (
              <></>
            );
          });
        }}
      </FieldArray>
    </Box>
  ) : (
    <CircularProgress />
  );
};

export default QualificationFormFields;
