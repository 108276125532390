import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Box } from '@medely/web-components';
import React from 'react';
import TopBarClean from './TopBarClean';
import TopBarDefault from './TopBarDefault';
import { useTheme } from '@medely/web-components/themes';

const useTopBarMode = (): 'default' | 'clean' | 'none' => {
  const isJobsScheduler = useRouteMatch('/jobs/new');
  const isJobEdit = useRouteMatch('/jobs/:id/edit');
  const isAssignmentJob = useRouteMatch('/scheduler/assignments/jobs/new');
  const isAssignmentsScheduler = useRouteMatch('/scheduler/assignments/new');
  const isTemplates = useRouteMatch('/templates/manager');
  const isNewTemplate = useRouteMatch('/templates/new');
  const isEditTemplate = useRouteMatch('/templates/:id/edit');
  const isThankYou = useRouteMatch('/thank-you');
  if (
    isJobsScheduler ||
    isJobEdit ||
    isAssignmentsScheduler ||
    isAssignmentJob ||
    isNewTemplate ||
    isEditTemplate
  ) {
    return 'clean';
  }
  if (isTemplates || isThankYou) {
    return 'none';
  }
  return 'default';
};

const TopBarContainer = (): React.ReactElement | null => {
  const mode = useTopBarMode();
  const theme = useTheme();
  if (mode === 'none') {
    return null;
  }
  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        borderBottom: mode === 'default' ? `solid 1px ${theme.palette.grey[100]}` : undefined,
        boxShadow: mode === 'default' ? 'none' : '0 4px 4px 0 rgba(0, 0, 0, 0.15)',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 4,
        padding: '0 8px',
        position: 'sticky',
        top: 0,
        zIndex: 10,
        '& > *:not(:last-child)': {
          marginRight: '24px',
        },
      }}
    >
      <Switch>
        <Route path="/jobs/new">
          <TopBarClean title="Create Shift" />
        </Route>
        <Route path="/jobs/:id/edit">
          <TopBarClean title="Edit Shift" />
        </Route>
        <Route path="/assignments/new">
          <TopBarClean title="Post New Assignment" />
        </Route>
        <Route path="/assignments/jobs/new">
          <TopBarClean title="Add Shift to Booked Assignment" />
        </Route>
        <Route path="/templates/:id/edit">
          <TopBarClean title="Edit Template" />
        </Route>
        <Route path="/templates/new">
          <TopBarClean title="Create Template" />
        </Route>
        <Route path="/pro-pool/invite">
          <TopBarClean title="Workforce Invite" />
        </Route>
        <Route>
          <TopBarDefault />
        </Route>
      </Switch>
    </Box>
  );
};

export default TopBarContainer;
