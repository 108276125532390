import { Box, Typography } from '@medely/web-components';
import React from 'react';

interface TopBarCleanProps {
  title: string;
}

const TopBarClean = ({ title }: TopBarCleanProps): React.ReactElement => (
  <Box py={2} px={[1, 0]}>
    <Typography variant="h6" weight="medium">
      {title}
    </Typography>
  </Box>
);

export default TopBarClean;
