import { useProfessionalTodos } from '@medely/pro-multi-platform';
import useCurrentUser from './useCurrentUser';

const useOnboarding = (): {
  assignmentsMissingConditions: number[];
  hasAnOnboardedPosition: boolean;
  isAssignmentOnboardingRequired: boolean;
  showOnboarding: boolean;
} => {
  const { currentUser } = useCurrentUser();
  const { todos } = useProfessionalTodos({ enabled: !!currentUser });

  const showOnboarding = currentUser?.professional?.professional_positions?.some(
    ({ completed_onboarding_at }) => !completed_onboarding_at,
  );

  const hasAnOnboardedPosition = currentUser?.professional?.professional_positions?.some(
    ({ completed_onboarding_at }) => completed_onboarding_at !== null,
  );

  return {
    assignmentsMissingConditions: todos?.assignments_missing_conditions ?? [],
    hasAnOnboardedPosition,
    isAssignmentOnboardingRequired: !!todos?.assignments_missing_conditions?.length,
    showOnboarding,
  };
};

export default useOnboarding;
