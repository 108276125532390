import { Box, Button, Heading, HStack, Icon, Label, Link, Text, VStack } from '@medely/ui-kit';
import { FixedFooterLayout } from '../../FixedFooterLayout/index';
import { StringParam, useQueryParam } from 'use-query-params';
import ProPool from '@src/assets/ProPool';
import React from 'react';
import { Timeline, useProNavigate } from '../../../index';

export const InviteAccepted = () => {
  const [facilityName] = useQueryParam('facilityName', StringParam);
  const { navigate } = useProNavigate();

  const timelineItems = [
    {
      id: 0,
      icon: <Icon boxSize="32px" name="person-outline" color="tertiary" />,
      label: '',
      description: <Text size="m">A facility member will further review your credentials.</Text>,
    },
    {
      id: 1,
      icon: <Icon boxSize="32px" name="file-plus" color="tertiary" />,
      label: '',
      description: (
        <Text size="m">
          A representative from {facilityName} may reach out to you via text or call for a quick
          interview.
        </Text>
      ),
    },
    {
      id: 2,
      icon: <Icon boxSize="32px" name="calendar-check" color="tertiary" />,
      label: '',
      description: (
        <Text size="m">
          If approved you&apos;ll receive a text and email with instructions for how to upload
          additional credentials.
        </Text>
      ),
    },
    {
      id: 3,
      icon: <Icon boxSize="32px" name="phone" color="tertiary" />,
      label: '',
      description: <Text size="m">Start booking shifts.</Text>,
    },
  ];

  const handleOkClick = () => {
    navigate('Today');
  };

  return (
    <FixedFooterLayout
      footerContent={
        <Button fullWidth onClick={handleOkClick}>
          Ok
        </Button>
      }
    >
      <VStack alignItems="center">
        <VStack alignItems="center" maxWidth="343px">
          <Box mb={2}>
            <ProPool />
          </Box>
          <VStack alignItems="center" mb={4}>
            <Heading size="s">Thank you for your interest</Heading>
            <Text size="l">We&apos;ll be back in touch soon.</Text>
          </VStack>
          <Box mb={2} px={1.5} py={2} bgColor="surface.secondary" borderRadius="8px">
            <Box mb={1.5}>
              <Label size="m">Here&apos;s what you can expect:</Label>
            </Box>
            <Timeline timelineItems={timelineItems} />
          </Box>
          <HStack position="absolute" bottom="92px">
            <Box pr={0.5}>
              <Text size="m">Have questions?</Text>
            </Box>
            <Link
              variant="text"
              size="m"
              url="https://help.medely.com/en/articles/10270319-what-are-professional-pools"
            >
              Learn more
            </Link>
            <Text size="m">.</Text>
          </HStack>
        </VStack>
      </VStack>
    </FixedFooterLayout>
  );
};
